<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(transactionId)="{ item }">
            <p
              class="m-0"
              :class="item.transactionId ? 'underline' : ''"
              @click="goTransactionDetail(item.refId)"
            >
              {{ item.transactionId || "-" }}
            </p>
          </template>

          <template #cell(invoiceNo)="{ item }">
            <p class="m-0">
              {{ item.invoiceNo || "-" }}
            </p>
          </template>

          <template #cell(bookingDate)="{ item }">
            <p class="m-0">
              {{
                $moment(item.bookingDate).locale("th").format("Do MMMM YYYY")
              }}
              ,
              {{ item.startTimeShow }}
            </p>
          </template>

          <template #cell(paymentTypeName)="{ item }">
            <div class="render-payment">
              <p class="m-0">
                {{ item.paymentTypeName || "-" }}
              </p>
              <div
                class="d-flex justify-content-center"
                v-if="item.showBtnFlag"
              >
                <b-button
                  variant="link"
                  class="btn main-color px-1 py-0 btn-link"
                  @click="openModalConfirm(item)"
                >
                  <b-icon icon="wallet2"></b-icon>
                </b-button>
              </div>
            </div>
          </template>

          <template #cell(statusName)="{ item }">
            <p class="m-0" :style="`color:${item.color}`">
              {{ item.statusName }}
            </p>
          </template>

          <template #cell(brand)="{ item }">
            <p class="m-0">
              {{ item.brandName }}
            </p>
          </template>
          <template #cell(branch)="{ item }">
            <p class="m-0">
              {{ item.venueName }}
            </p>
          </template>

          <template #cell(grandTotal)="{ item }">
            <p class="m-0">
              {{ formatNumber(item.grandTotal) }}
            </p>
          </template>

          <template #cell(promocodeSerialNo)="{ item }">
            <p class="m-0">
              {{ item.promocodeSerialNo || "-" }}
            </p>
          </template>

          <template #cell(fullName)="{ item }">
            <p class="m-0">
              {{ item.fullName || "-" }}
            </p>
          </template>

          <template #cell(email)="{ item }">
            <p class="m-0">
              {{ item.email || "-" }}
            </p>
          </template>

          <template #cell(telephone)="{ item }">
            <p class="m-0">
              {{ item.telephone || "-" }}
            </p>
          </template>

          <template #cell(note)="{ item }">
            <p class="m-0">
              {{ item.note || "-" }}
            </p>
          </template>

          <template #cell(createdTime)="{ item }">
            <p class="m-0">
              {{
                $moment(item.createdTime).locale("th").format("Do MMMM YYYY")
              }}
            </p>
          </template>

          <template #cell(paymentTime)="{ item }">
            <p class="m-0">
              {{
                item.paymentTime
                  ? $moment(item.paymentTime)
                      .locale("th")
                      .format("Do MMMM YYYY, h:mm")
                  : "-"
              }}
            </p>
          </template>
          <template #cell(actions)="{ item }">
            <font-awesome-icon
              class="mr-2 pointer"
              icon="pen"
              @click="goTransactionDetail(item.refId)"
            />
          </template>

          <!-- <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="btn main-color px-1 py-0 btn-link"
                @click="openModalConfirm(item)"
              >
                <b-icon icon="wallet2"></b-icon>
              </b-button>
            </div>
          </template> -->

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    goTransactionDetail(refId) {
      if (!refId) {
        return;
      }

      this.$router.push(`/transaction/details/${refId}`);
    },
    openModalConfirm(obj) {
      let { refId } = obj;

      this.$emit("getPaymentInfo", refId);
    },
    // handleDelete(item) {
    //   this.$emit("handleDelete", item);
    // },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.render-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
