<template>
  <div>
    <b-modal
      v-model="isModal"
      id="modal-confirm-payment"
      size="xl"
      hide-header
      hide-footer
      scrollable
    >
      <div class="modal-comfirm-payment" v-if="dataPayment">
        <div class="header">
          <p class="m-0">รายละเอียดการชำระเงิน</p>
        </div>
        <div class="body">
          <b-row>
            <b-col class="left min-height-body" cols="4">
              <div class="left-header">
                <b-row no-gutters>
                  <b-col cols="12" xl="6">
                    <p class="m-0">รายการชำระเงิน</p>
                  </b-col>
                  <b-col cols="12" xl="6">
                    <p class="m-0 sum-price">
                      ยอดชำระเงินรวม {{ formatNumber(totalAmount) }}
                    </p>
                  </b-col>
                </b-row>
              </div>

              <div
                class="mb-2"
                v-for="(item, index) in dataPayment.bankTransferList"
                :key="index"
              >
                <b-row no-gutters>
                  <b-col
                    :cols="item.statusItem ? 11 : 12"
                    @click="selectBankList(item, index)"
                  >
                    <b-row
                      class="left-card h-full"
                      :class="
                        $v.dataPayment.bankTransferList.$each[index].$error
                          ? 'error-card'
                          : ''
                      "
                      no-gutters
                    >
                      <b-col class="list-detail" cols="8">
                        <p class="m-0">
                          {{
                            $moment(item.date)
                              .locale("th")
                              .format("Do MMMM YYYY , HH:mm")
                          }}
                        </p>
                        <div class="bank-detail" v-show="item.bankAccountName">
                          <img
                            class="icon bank"
                            :src="item.bankAccountBankImgUrlPath"
                          />
                          <div>
                            <p class="m-0">
                              {{ item.bankAccountName }}
                            </p>
                            <p class="m-0 dexcription-bank">
                              {{ item.bankAccountNumber }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        class="price"
                        :class="selectBank == item ? 'active' : ''"
                        cols="4"
                      >
                        <p class="m-0">
                          {{ formatNumber(item.amount || 0) }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" v-if="item.statusItem" align-self="center">
                    <font-awesome-icon
                      icon="trash-alt"
                      class="ft-14 ml-2"
                      @click="removeItem(item)"
                    />
                  </b-col>
                </b-row>
              </div>

              <div class="btn-center" v-if="dataPayment.canEditFlag">
                <b-button class="btn-filter" @click="addNewPayment">
                  เพิ่มรายการ
                </b-button>
              </div>
            </b-col>

            <b-col class="center" cols="4">
              <div v-if="selectBank">
                <img
                  class="img-selectbank"
                  :src="
                    selectBank.base64.base64
                      ? selectBank.base64.base64
                      : selectBank.transactionDetail.slipImgUrlPath
                  "
                />
              </div>
            </b-col>
            <b-col class="right" cols="4">
              <div class="right-text">
                <p class="m-0">Invoice No :</p>
                <p class="m-0">
                  {{ dataPayment.invoiceNo }}
                </p>
              </div>
              <div class="right-text">
                <p class="m-0">Transaction Id :</p>
                <p class="m-0">
                  {{ dataPayment.transactionShowId }}
                </p>
              </div>
              <div class="right-text">
                <p class="m-0">Round :</p>
                <p class="m-0">
                  {{ dataPayment.startTimeShow }}
                </p>
              </div>
              <div
                class="right-text"
                v-for="(item, index) in dataPayment.productList
                  .productDetailLists"
                :key="index"
              >
                <p class="m-0">{{ item.productName }}</p>
                <p class="m-0">
                  {{ formatNumber(item.price) }}
                </p>
              </div>

              <div class="right-text">
                <p class="m-0">ราคาสุทธิ :</p>
                <p class="m-0">
                  {{ formatNumber(dataPayment.productList.grandTotal) }}
                </p>
              </div>

              <InputSelect
                v-if="selectBank"
                v-model="selectBank.sourceBankId"
                :options="optionBank"
                title="ธนาคารต้นทาง"
                class="mt-2"
                valueField="bankId"
                textField="name"
                isRequired
                :disabled="!dataPayment.canEditFlag"
                :isValidate="
                  $v.dataPayment.bankTransferList.$each[selectBankIndex]
                    .sourceBankId.$error
                "
                :v="
                  $v.dataPayment.bankTransferList.$each[selectBankIndex]
                    .sourceBankId
                "
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="null" disabled>
                    กรุณาเลือกธนาคาร
                  </b-form-select-option>
                </template>
              </InputSelect>

              <InputSelect
                v-if="selectBank"
                v-model="selectBank.bankAccountId"
                :options="optionPayment"
                title="ธนาคารปลายทาง"
                class="mt-2"
                valueField="bankAccountId"
                textField="bank"
                isRequired
                :disabled="!dataPayment.canEditFlag"
                :isValidate="
                  $v.dataPayment.bankTransferList.$each[selectBankIndex]
                    .bankAccountId.$error
                "
                :v="
                  $v.dataPayment.bankTransferList.$each[selectBankIndex]
                    .bankAccountId
                "
                @onDataChange="changeBank"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="null" disabled>
                    กรุณาเลือกธนาคาร
                  </b-form-select-option>
                </template>
              </InputSelect>

              <div v-if="selectBank">
                <!-- <InputText
                  v-model="selectBank.transactionDetail.transactionShowId"
                  textFloat="Transaction Id"
                  placeholder="Transaction Id"
                  type="number"
                  name="transactionid"
                  disabled
                /> -->

                <InputText
                  v-model="selectBank.transactionDetail.email"
                  textFloat="Email"
                  placeholder="Email"
                  type="email"
                  name="Email"
                  disabled
                />

                <InputText
                  v-model="selectBank.transactionDetail.bankTransferAmount"
                  textFloat="จำนวนเงินที่โอน"
                  placeholder="จำนวนเงินที่โอน"
                  type="number"
                  name="bankTransferAmount"
                  @input="changeBankTransfer"
                  isRequired
                  :disabled="!dataPayment.canEditFlag"
                  :isValidate="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .amount.$error
                  "
                  :v="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .amount
                  "
                />

                <InputText
                  v-model="selectBank.transactionDetail.dateOnly"
                  textFloat="Date"
                  placeholder="Date"
                  type="date"
                  name="bankTransferdate"
                  isRequired
                  :disabled="!dataPayment.canEditFlag"
                  :isValidate="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .transactionDetail.dateOnly.$error
                  "
                  :v="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .transactionDetail.dateOnly
                  "
                />

                <!-- <InputText
                  v-model="selectBank.transactionDetail.timeOnly"
                  textFloat="Time"
                  placeholder="Time"
                  type="time old"
                  name="bankTransferTime"
                  isRequired
                  :disabled="!dataPayment.canEditFlag"
                  :isValidate="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .transactionDetail.timeOnly.$error
                  "
                  :v="
                    $v.dataPayment.bankTransferList.$each[selectBankIndex]
                      .transactionDetail.timeOnly
                  "
                /> -->
                <div class="mb-2">
                  <p class="font-15">
                    Time
                    <span class="text-error">*</span>
                  </p>
                  <div
                    class="input-container mr-2"
                    :class="
                      $v.dataPayment.bankTransferList.$each[selectBankIndex]
                        .transactionDetail.timeOnly.$error && 'error'
                    "
                  >
                    <datetime
                      v-model="selectBank.transactionDetail.timeOnly"
                      :disabled="!dataPayment.canEditFlag"
                      :input-style="styleDatetime"
                      type="time"
                      format="HH:mm"
                      value-zone="local"
                      placeholder="Time From"
                      ref="paymentTime"
                    >
                    </datetime>
                    <div
                      class="icon-container text-right"
                      @click="$refs.paymentTime.isOpen = true"
                    >
                      <b-icon
                        icon="clock-fill"
                        class="float-right"
                        color="#B41BB4"
                      ></b-icon>
                    </div>
                  </div>
                  <p
                    class="text-error m-0"
                    v-if="
                      $v.dataPayment.bankTransferList.$each[selectBankIndex]
                        .transactionDetail.timeOnly.$error
                    "
                  >
                    Please select time
                  </p>
                </div>

                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                    md="12"
                    v-if="dataPayment.canEditFlag"
                  >
                    <div>
                      <b-button class="btn-filter m-120" @click="uploadImage">
                        Upload Image
                      </b-button>
                      <p
                        class="text-red"
                        v-if="
                          $v.dataPayment.bankTransferList.$each[selectBankIndex]
                            .imageName.$error
                        "
                      >
                        Please upload image
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="6" md="12">
                    <p class="m-0 ml-2">
                      {{ selectBank.imageName }}
                    </p>
                    <input
                      class="d-none"
                      ref="imagePayment"
                      type="file"
                      accept="image/*"
                      @change="changeImagePayment"
                    />
                  </b-col>
                </b-row>

                <div class="d-flex align-items-center" v-if="0">
                  <div>
                    <b-button
                      class="btn-filter m-120"
                      @click="uploadImage"
                      v-if="dataPayment.canEditFlag"
                    >
                      Upload Image
                    </b-button>
                    <p
                      class="text-red"
                      v-if="
                        $v.dataPayment.bankTransferList.$each[selectBankIndex]
                          .imageName.$error
                      "
                    >
                      Please upload image
                    </p>
                  </div>

                  <p class="m-0 ml-2">
                    {{ selectBank.imageName }}
                  </p>

                  <input
                    class="d-none"
                    ref="imagePayment"
                    type="file"
                    accept="image/*"
                    @change="changeImagePayment"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="footer">
          <b-button
            class="btn-filter confirm"
            :disabled="isLoading"
            @click="confirmPayment"
            v-if="
              dataPayment.canEditFlag && dataPayment.bankTransferList.length > 0
            "
          >
            ยืนยัน
            <b-spinner
              v-if="isLoading"
              class="b-loading"
              label="Spinning"
              small
            ></b-spinner>
          </b-button>
          <b-button class="btn-filter" @click="closeModal" v-else>
            ปิด
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import Axios from "axios";

export default {
  props: {
    dataPayment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectBank: null,
      selectBankIndex: null,
      form: {
        image: null,
      },
      optionPayment: [],
      optionBank: [],

      // state toggle
      isModal: false,
      isLoading: false,

      // state create ID frontEnd
      fakeID: 1000,

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  validations() {
    return {
      dataPayment: {
        bankTransferList: {
          $each: {
            sourceBankId: { required },
            bankAccountId: { required },
            amount: {
              minValue: minValue(1),
              required,
            },
            imageName: { required },

            transactionDetail: {
              dateOnly: { required },
              timeOnly: { required },
            },
          },
        },
      },
    };
  },
  watch: {
    isModal(val) {
      if (val) {
        this.selectBankList(this.dataPayment.bankTransferList[0], 0);
        this.getAllOptionPayment();
      }
    },
  },
  computed: {
    totalAmount() {
      const sum = this.dataPayment.bankTransferList.reduce((x, y) => {
        return x + y.amount;
      }, 0);
      return sum;
    },
  },
  methods: {
    async getAllOptionPayment() {
      const getDataPayment = await this.$services.master.getOptionPayment(
        this.dataPayment.eventId
      );

      if (getDataPayment.result == 1) {
        this.optionPayment = getDataPayment.detail;
      }

      const getDataBank = await this.$services.master.getOptionBank();

      if (getDataBank.result == 1) {
        this.optionBank = getDataBank.detail;
      }
    },
    async confirmPayment() {
      this.$v.dataPayment.$touch();

      if (this.$v.dataPayment.$error) {
        return;
      }

      const body = {
        transactionStatusId: 1,
        remark: "appprove by frontend",
        bankTransferList: this.convertBankTransferList(
          this.dataPayment.bankTransferList
        ),
      };

      const refId = this.dataPayment.refId
        ? this.dataPayment.refId
        : this.$route.params.id;

      this.isLoading = true;

      const update = await this.$services.transaction.submitTransaction(
        refId,
        body
      );

      if (update.result == 1) {
        this.$emit("confirmSuccess");
        this.isLoading = false;
        this.closeModal();
      }
    },
    convertBankTransferList(arr) {
      let final = arr.map((x) => {
        let fulltime = `${x.transactionDetail.dateOnly}T${this.convertTimeHHMM(
          x.transactionDetail.timeOnly
        )}`;

        return {
          bankTransferId: x.bankTransferId || 0,
          sourceBankId: x.sourceBankId,
          transferBankAccountId: x.bankAccountId,
          amount: x.amount,
          date: this.$moment(fulltime).format(),
          slipImgUrl: x.transactionDetail.slipImgUrl,
          base64: x.base64.base64
            ? {
                base64: x.base64.base64,
              }
            : null,
        };
      });

      return final;
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm-payment");
    },
    changeBankTransfer(val) {
      this.selectBank.amount = Number(val);
    },
    addNewPayment() {
      this.fakeID++;
      const body = {
        statusItem: "new",
        statusID: this.fakeID,
        bankTransferId: null,
        sourceBankId: null,
        sourceBankName: "",
        sourceBankImgUrlPath: "",
        bankAccountId: null,
        bankAccountBankId: 0,
        bankAccountName: "",
        bankAccountNumber: "",
        bankAccountBankImgUrlPath: "",
        transferBankAccountId: 0,
        date: this.$moment(new Date()).format(),
        amount: null,
        activeStatus: 0,
        transactionDetail: {
          transactionId: null,
          transactionShowId: "",
          email: "",
          bankTransferAmount: null,
          date: this.$moment(new Date()).format(),
          dateOnly: this.$moment(new Date()).format("YYYY-MM-DD"),
          timeOnly: this.$moment(new Date()).format("HH:mm"),
          slipImgUrl: "",
          slipImgUrlPath: "",
        },
        base64: {
          base64: null,
        },
        imageName: "",
      };

      this.dataPayment.bankTransferList.push(body);
    },
    removeItem(obj) {
      this.dataPayment.bankTransferList =
        this.dataPayment.bankTransferList.filter((x) => {
          return x.statusID != obj.statusID;
        });

      if (this.selectBank == obj) {
        this.selectBank = null;
      }
    },
    changeBank(value) {
      const findOption = this.optionPayment.find((x) => {
        return x.bankAccountId == value;
      });

      this.selectBank.bankAccountName = findOption.bank;
      this.selectBank.bankAccountNumber = findOption.accountNumber;
      this.selectBank.bankAccountBankImgUrlPath = findOption.fullPathImgUrl;
    },
    changeImagePayment(img) {
      this.encodeImageFileAsURL(img.target.files);
    },
    encodeImageFileAsURL(element) {
      this.selectBank.imageName = element[0].name;

      const vm = this;
      var file = element[0];
      var reader = new FileReader();

      reader.onloadend = function () {
        vm.selectBank.base64.base64 = reader.result;
      };
      reader.readAsDataURL(file);
    },
    convertTime(time) {
      let getTime = `${this.$moment().format("YYYY-MM-DDT")}${time}`;

      return getTime;
    },

    convertTimeHHMM(time) {
      return this.$moment(time).format("HH:mm");
    },
    uploadImage() {
      this.$refs.imagePayment.click();
    },
    selectBankList(obj, index) {
      this.selectBank = obj;
      this.selectBankIndex = index;
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-comfirm-payment {
  .header {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .body {
    .left {
      &.min-height-body {
        min-height: 70vh;
      }

      .left-header {
        .sum-price {
          text-align: right;
        }
      }

      .left-card {
        .h-full {
          height: 100%;
        }

        border: 1px solid black;
        border-radius: 8px;
        cursor: pointer;

        &.error-card {
          border: 2px solid red !important;
        }

        .list-detail {
          padding: 10px;

          .bank-detail {
            display: flex;
            align-items: flex-start;
            margin-top: 5px;

            .dexcription-bank {
              opacity: 0.5;
            }

            .icon {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }
          }
        }

        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 80px;
          background: var(--secondary-color);
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;

          &.active {
            background: #8f20c6;
            color: white;
          }
        }
      }
      .btn-center {
        text-align: center;
      }
    }

    .center {
      background: gainsboro;

      .img-selectbank {
        width: 100%;
        padding: 15px 0px;
      }
    }

    .right {
      .right-text {
        display: flex;
        justify-content: space-between;
      }
      .font-15 {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 5px;
      }
    }
  }

  .footer {
    padding-top: 15px;
    text-align: right;
  }

  .text-red {
    color: red;
  }
}

@media screen and (max-width: 1200px) {
  .sum-price {
    text-align: left !important;
  }
}

// ! override
.btn-filter {
  min-width: 100px;

  &.confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  &.m-120 {
    min-width: 120px;
  }

  .b-loading {
    margin-left: 5px;
  }
}
</style>
